import { AppBar, Container, createStyles, Hidden, makeStyles, Theme, Toolbar, useTheme, Box } from "@material-ui/core";
import React from "react";
import { useStateValue } from "../hooks/StateContext";
import { setShowContactModal } from "./Controls/Actions/HeaderActions";
import ContactModal from "./Controls/ContactModal";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        offset: {
            [theme.breakpoints.only('xs')]: {
                minHeight: '56px',
            },
            [theme.breakpoints.up('sm')]: {
                minHeight: theme.header.minHeight ? `${theme.header.minHeight}px` : '64px',
            },
        },
        appbar: {
            backgroundColor: theme.verticalMargins.headerBackgroundColour,
        },
        headerLogoDiv: {
            [theme.breakpoints.up('sm')]: {
                flexGrow: 1,
                alignSelf: theme.logoVerticalAlignment,
            },
        },
        headerText: {
            fontSize: '0.75rem',
            color: theme.palette.primary.main,
            [theme.breakpoints.down(540)]: {
                fontSize: '0.6875rem',
            },
            [theme.breakpoints.down(400)]: {
                fontSize: '0.625rem',
            },
        },
        headerMainText: {
            fontSize: '1rem',
            color: theme.palette.primary.main,
            [theme.breakpoints.down(540)]: {
                fontSize: '0.8125rem',
            },
            [theme.breakpoints.down(400)]: {
                fontSize: '0.75rem',
            },
        },
        headerLink: {
            flexGrow: 1,
            color: theme.palette.primary.main,
            fontFamily: "Spartan MB Bold",
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
            fontSize: '1rem',
            [theme.breakpoints.down(540)]: {
                fontSize: '0.8125rem',
            },
            [theme.breakpoints.down(400)]: {
                fontSize: '0.75rem',
            },
        },
        telephone: {
            flexGrow: 1,
            textAlign: 'right',
            [theme.breakpoints.up('sm')]: {
                alignSelf: theme.telephoneHelpVerticalAlignment,
                margin: '20px 0',
            }
        },
        styledLink: {
            color: theme.header.textColor,
            fontFamily: "Spartan MB Bold",
            fontSize: "0.6875rem",
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
        },
        defaultText: {
            display: 'block',
            cursor: 'pointer',
            color: theme.header.textColor,
            fontSize: "0.6875rem",
        },
        desktopText: {
            fontSize: '1.125rem',
        },
        toolbar: {
            [theme.breakpoints.only('xs')]: {
                minHeight: '56px',
            },
            [theme.breakpoints.up('sm')]: {
                minHeight: theme.header.minHeight ? `${theme.header.minHeight}px` : '64px',
            },
        },
        logo: {
            [theme.breakpoints.up('sm')]: {
                height: `${40 * theme.metadata.logo.scale}px`,
                margin: '20px 0',
            },
            height: '40px',
        },
        operatedByLogo: {
            [theme.breakpoints.up('sm')]: {
                height: '36px',
                margin: '10px 0',
            },
            height: '30px',
        },
    })
});

const HomepageHeader = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useStateValue();

    const handleOpen = () => {
        dispatch(setShowContactModal(true));
    };

    const handleClose = () => {
        dispatch(setShowContactModal(false));
    };

    return (
        <>
            <AppBar position="fixed" className={classes.appbar}>
                <Container maxWidth={!theme.header.fullWidth ? 'md' : 'xl'}>
                    <Toolbar disableGutters className={classes.toolbar}>
                        <div className={classes.headerLogoDiv}>
                            <img src={theme.metadata.logoUrl} className={classes.logo} />
                        </div>
                        <div className={classes.telephone}>
                            <Hidden smUp>
                                <a onClick={handleOpen} className={classes.styledLink}>Need help?</a>
                            </Hidden>
                            <Hidden only={'xs'}>
                                <a onClick={handleOpen} className={classes.defaultText}>Need help?</a>
                                <a href={`tel:${theme.metadata.phoneNumber}`} className={`${classes.styledLink} ${classes.desktopText}`}>
                                    {theme.metadata.phoneNumber}
                                </a>
                            </Hidden>
                            {
                                theme.metadata.operatedByLogoUrl &&
                                <Box>
                                    <img src={theme.metadata.operatedByLogoUrl} className={classes.operatedByLogo} />
                                </Box>
                            }
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            <div className={classes.offset} />
            <ContactModal
                open={state.ShowContactModal}
                title="We're here to help."
                content={`Our UK based team of insurance experts are here to help. How would you like to get in touch?`}
                onClose={handleClose}
                showCloseButton={false}
            />
        </>
    );
};

export default HomepageHeader;