import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import { navigate } from "@reach/router";
import React from "react";
import ContinueButton from "../components/Controls/ContinueButton";
import CookieBar from "../components/Controls/CookieBar";
import SectionTitle from "../components/Controls/SectionTitle";
import Footer from "../components/Footer";
import HomepageHeader from "../components/HomepageHeader";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        content: {
            [theme.breakpoints.only('xs')]: {
                marginTop: '56px',
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.header.minHeight ? `${theme.header.minHeight}px` : '64px',
            },
            minHeight: '100vh',
        },
    });
});

const browser = typeof window !== "undefined" && window;

const errorPage = () => {
    const classes = useStyles();

    const newInsuranceQuote = () => {
        navigate("/direct/details");
    };

    return (
        browser &&
        <>
            <HomepageHeader />
            <Container className={classes.content} maxWidth="sm">
                <SectionTitle title={"Sorry, page not found."} subHeading="Please click below to get an insurance quote." />
                <ContinueButton onClick={newInsuranceQuote}>Get Quote</ContinueButton>
            </Container>
            <Footer />
            <CookieBar />
        </>
    );
};

export default errorPage;