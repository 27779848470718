import { createStyles, makeStyles, Theme, Typography, Grid } from "@material-ui/core";
import React from 'react';

const useStyles = (useLighterSubHeading: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        titleText: {
            [theme.breakpoints.up(350)]: {
                fontSize: '1.7rem',
                lineHeight: '1.875rem',
            },
            [theme.breakpoints.up(400)]: {
                fontSize: '1.875rem',
                lineHeight: "2.5rem",
            },
            [theme.breakpoints.down(350)]: {
                fontSize: '1.3125rem',
                lineHeight: '1.875rem',
            },
            marginBottom: "10px",
            marginTop: "2.5rem",
            fontFamily: "Spartan MB ExtraBold",
            whiteSpace: 'pre-wrap',
        },
        subHeading: {
            fontSize: useLighterSubHeading ? '1rem' : '1.1875rem',
            margin: "10px 0",
            marginBottom: "1.875rem",
            color: useLighterSubHeading ? theme.palette.text.primary : theme.palette.text.secondary,
            fontFamily: useLighterSubHeading ? 'Spartan MB SemiBold' : 'Spartan MB ExtraBold',
            [theme.breakpoints.down(350)]: {
                fontSize: '1rem',
            },
        },
        headingDiv: {
            maxWidth: '100%',
        },
        headingLogo: {
            position: 'absolute',
            bottom: '0px',
            right: '0px',
        },
        imageGrid: {
            position: 'relative',
        },
    })
);

type Props = {
    title: string;
    subHeading?: string;
    subHeadingClass?: string;
    testId?: string;
    icon?: string;
    useLighterSubHeading?: boolean;
};

const SectionTitle = ({ title, subHeading, subHeadingClass, testId, icon, useLighterSubHeading }: Props) => {
    const classes = useStyles(!!useLighterSubHeading)();

    return (
        <>
            <div className={classes.headingDiv}>
                <Grid container>
                    <Grid item xs={icon ? 9 : 12}>
                        <Typography data-testid={testId || "heading"} variant="h1" color="textSecondary" className={classes.titleText}>
                            {title}
                        </Typography>
                    </Grid>
                    {icon &&
                        <Grid item xs={3} className={classes.imageGrid} data-testid="logo">
                            <img src={icon} className={classes.headingLogo} alt="Heading Logo" />
                        </Grid>
                    }
                </Grid>
            </div>
            {subHeading &&
                <Typography variant="h2" className={`${classes.subHeading} ${subHeadingClass ? subHeadingClass : ''}`} data-testid="subheading">
                    {subHeading}
                </Typography>
            }
        </>
    );
};

export default SectionTitle;